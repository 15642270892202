/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { getAuthority } from '@/utils/authority';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'login' }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import("@/views/login/Login.vue")
  },
  {
    path: '/report/list',
    name: 'list',
    component: () => import("@/views/report/List.vue")
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import("@/views/report/Detail.vue")
  },
  {
    path: '/disease/list',
    name: 'DiseaseList',
    component: () => import("@/views/disease/List.vue")
  },
  {
    path: '/doctor/list',
    name: 'DoctorList',
    component: () => import("@/views/doctor/List.vue")
  },
  {
    path: '*',
    name: '404',
    component: () => import("@/views/404.vue")
  },
  // {
  //   path: '/doctor/list',
  //   name: 'doctor_list',
  //   component: () => import("@/views/doctor/List.vue")
  // },
  // {
  //   path: '/doctor/detail',
  //   name: 'doctor_detail',
  //   component: () => import("@/views/doctor/Detail.vue")
  // },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {

  const authority = getAuthority()
  const USER_INFO = localStorage.getItem('USER_INFO')

  if ( authority && USER_INFO ) {
    next()
  } else {
    if (to.name !== 'login') {
      next({ name: 'login' })
      return false
    } else {
      next()
    }
  }
})

export default router

// 定义常量

// 请求超时时间毫秒

export const IS_PROD = process.env.NODE_ENV === 'production'

// export const HOST = IS_PROD ? 'http://localhost:8800' : ''
export const HOST = 'http://43.143.241.171';
// export const HOST = '';
export const TIME_OUT = 60 * 1000;
export const BASE_URL = '/api/check';
// export const BASE_URL = '/api/admin';
// token 键
export const TOKEN = 'check-token'; // 更新登录状态
export const TOKEN_PREFIX = ' '; // 更新登录状态
export const Array_Prefix = 'array_prefix_'; // 更新登录状态
export const Auth_All = '0'; // 管理员（所有部门）
export const Auth_Surgery = '1'; //外科
export const Auth_Stomatology = '2'; // 口腔科
export const Auth_Imaging = '3'; // 影像科
export const Auth_Laboratory = '4'; // 化验室
export const Auth_CT = '5'; // CT科
export const COMPLETED = 'COMPLETED'; // 已完成
export const INCOMPLETE = 'INCOMPLETE'; // 未完成



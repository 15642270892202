<template>
  <div id="app" class="flex" @click="checkTimeout">
    <el-container>
      <transition name="slide-menu">
        <el-aside :isCollapse="showMenu" v-if="$route.name !== 'login' && showMenu"  width="200px" class="left-aside flex_column">
          <div class="h100">
            <transition name="slide-menu">
              <CommonAside />
            </transition>
          </div>
        </el-aside>
      </transition>


      <el-container>
        <el-header  v-if="$route.name !== 'login'" class="top_bar "
                    :class="{'showMenu': showMenu}">
          <div class=" flex_center flex_none h100 ">
            <div class="" v-if="showMenu">
              <i class="el-icon-s-fold fs36" @click="showMenu = !showMenu;"></i>
            </div>
            <div class="" v-if="!showMenu">
              <i class="el-icon-s-unfold fs36" @click="showMenu = !showMenu;"></i>
            </div>

            <router-link class="p16" to="/report/list" style="text-decoration: none;">
              <h2 class="inb" style="color: #419fff">体检录入系统</h2>
            </router-link>

            <div class="flex_auto  " style="line-height: 50px">
<!--              <el-link v-if="$route.name !== 'list'"-->
<!--                       :underline="false" class="inb fs24 pr pl16" style="top: -3px">-->
<!--                <i-->
<!--                    class="el-icon-arrow-left"-->
<!--                    @click="$router.back()"></i>-->
<!--              </el-link>-->
            </div>
            <div class="w240px flex_none tr mr16" >
              欢迎你：{{this.$store.state.userInfo.nickName}}    ({{this.$store.state.userInfo.deptName}})
            </div>
            <div class="w80px flex_none tr">
              <el-button class="mr16" @click="logout">退 出</el-button>
            </div>
          </div>
        </el-header>

        <router-view :class="{'showTop' :$route.name !== 'login', 'showMenu': $route.name !== 'login' & showMenu}" >
        </router-view>


      </el-container>
    </el-container>



<!--    <div class="flex_auto pr" style="height: 200px">-->
<!--      <router-view />-->
<!--    </div>-->
    <div class="footer_bar flex_center" >
      <el-link href="https://beian.miit.gov.cn/" type="info ">豫ICP备2024040802号-1</el-link>
    </div>
  </div>
</template>

<script>
import { removeAuthority } from "@/utils/authority";
import CommonAside from "@/components/CommonAside.vue";

export default {
  data(){
    return{
      showMenu: false,
      lastTime: null,
      timeOut : 30 * 60 * 1000, //设置超时时间： 30分
      // timeOut : 10 * 1000, //超时时间（调试）： 10秒
    }
  },
  components:{
    CommonAside
  },
  mounted() {
    const userInfo = localStorage.getItem("USER_INFO") || "";
    this.$store.commit(
      "SET_USER_INFO",
      userInfo ? JSON.parse(userInfo) : userInfo
    );
  },
  created() {
    this.lastTime  =new Date().getTime();
  },
  methods: {
    checkTimeout() {
      console.log("checkTimeout");
      let currentTime = new Date().getTime(); //更新当前时间
      if (currentTime - this.lastTime > this.timeOut) { //判断是否超时
        //注意这里写的是退出登录时做的操作
        this.$message.success('您的登录已超时，请重新登录');
        removeAuthority();
        this.$store.commit("SET_USER_INFO", {});
        this.$router.push({ name: "login" });
      }

      this.lastTime = new Date().getTime();// 记录最后一次点击时间
    },
    logout() {
      this.$confirm("是否退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeAuthority();
          this.$store.commit("SET_USER_INFO", {});
          this.$router.push({ name: "login" });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less">
@import "./style/default.less";
.top_bar {
  padding: 0px;
  height: 50px;
  width: 100%;
  box-shadow: 0 0 12px rgba(0,0,0, 0.2);
  z-index: 100;
  position: fixed;
  top:0 ;
}
.left-aside{
  position: fixed;
  height: 100%;
}
.showMenu{
  margin-left: 200px;
  width: calc(100% - 200px);
}
.showTop{
  margin-top: 60px;
  height: calc(100% - 60px);
}

.footer_bar {
  height: 30px;
  width: 100%;
  box-shadow: 0 0 12px rgba(0,0,0, 0.2);
  position: absolute;
  bottom:0;
  z-index: 50;
}
</style>

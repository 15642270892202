<template>
  <div class="h100 left_aside">
    <el-menu default-active="1" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse">
      <router-link to="/report/list">
        <el-menu-item index="1">
          <i class="el-icon-menu"></i>
          <span slot="title">档案管理</span>
        </el-menu-item>
      </router-link>

      <el-submenu index="2">
        <template slot="title">
          <i class="el-icon-setting"></i>
          <span slot="title">系统管理</span>
        </template>
        <el-menu-item-group>
          <router-link to="/doctor/list">
            <el-menu-item index="2-1">医生管理</el-menu-item>
          </router-link>

<!--          <router-link to="/report/list">-->
<!--            <el-menu-item index="1-2">部门管理</el-menu-item>-->
<!--          </router-link>-->
<!--          <router-link to="/report/list">-->
<!--            <el-menu-item index="1-3">角色管理</el-menu-item>-->
<!--          </router-link>-->
          <router-link to="/disease/list">
            <el-menu-item   index="2-2" >疾病解释管理</el-menu-item>
          </router-link>
        </el-menu-item-group>

      </el-submenu>

<!--      <el-menu-item index="3" disabled>-->
<!--        <i class="el-icon-document"></i>-->
<!--        <span slot="title">导航三</span>-->
<!--      </el-menu-item>-->
<!--      <el-menu-item index="4">-->
<!--        <i class="el-icon-setting"></i>-->
<!--        <span slot="title">导航四</span>-->
<!--      </el-menu-item>-->
    </el-menu>
<!--    <el-menu-->
<!--        default-active="2"-->
<!--        class="el-menu-vertical-demo h100"-->
<!--        @open="handleOpen"-->
<!--        @close="handleClose"-->
<!--        background-color="rgb(222 222 222)"-->
<!--        text-color="#000"-->
<!--        active-text-color="rgb(64 158 255)">-->
<!--      <el-submenu index="1">-->
<!--        <template slot="title">-->
<!--          <i class="el-icon-location"></i>-->
<!--          <span>档案管理</span>-->
<!--        </template>-->
<!--      </el-submenu>-->
<!--      <el-menu-item index="2">-->
<!--        <template slot="title">-->
<!--          <i class="el-icon-menu"></i>-->
<!--          <span slot="title">导航二</span>-->
<!--        </template>-->
<!--        <el-menu-item-group>-->
<!--          &lt;!&ndash;          <template slot="title">分组一</template>&ndash;&gt;-->
<!--          <el-menu-item index="2-1"></el-menu-item>-->
<!--          <el-menu-item index="2-2"></el-menu-item>-->
<!--        </el-menu-item-group>-->
<!--        <el-menu-item-group >-->
<!--          <el-menu-item index="2-3">选项3</el-menu-item>-->
<!--        </el-menu-item-group>-->
<!--        <el-submenu index="2-4">-->
<!--          <template slot="title">选项4</template>-->
<!--          <el-menu-item index="2-4-1">选项1</el-menu-item>-->
<!--        </el-submenu>-->

<!--      </el-menu-item>-->
<!--      <el-menu-item index="3" disabled>-->
<!--        <i class="el-icon-document"></i>-->
<!--        <span slot="title">导航三</span>-->
<!--      </el-menu-item>-->
<!--      <el-menu-item index="4">-->
<!--        <i class="el-icon-setting"></i>-->
<!--        <span slot="title">导航四</span>-->
<!--      </el-menu-item>-->
<!--    </el-menu>-->
  </div>
</template>

<script>
export default {

  data() {
    return {

    };
  },
  props:['isCollapse'],
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.left_aside{

}
a{
  text-decoration: none;
}
.router-link-active {
  text-decoration: none;
}
</style>


